import {VisaActionTypes, VisaTypeAction} from '../actions/visa-type.action';

const initialState = {
    name: null,
    id: null
}

export function VisaTypeReducer(state: any = initialState, action: VisaTypeAction) {
    switch (action.type) {
        case VisaActionTypes.ADD_VISA:
            return state = action.payload;
        default:
            return state;
    }
}
