import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TestimonialsComponent } from './testimonials.component';

@NgModule({
  declarations: [
    TestimonialsComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
    TranslateModule.forChild()
  ],
  exports: [
    TestimonialsComponent
  ]
})
export class TestimonialsModule {
}
