import { Component, OnInit, OnDestroy } from '@angular/core';
import {StepsService} from '../../services/steps/steps.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.scss']
})
export class EmploymentComponent implements OnInit, OnDestroy {

  public loader = false;

  public employed = {
    name: 'employed',
    error: null,
    list: [
      {
        title: 'No',
        icon: '../../../assets/images/no.png',
        iconActive: '../../../assets/images/no_color.png',
        iconText: null,
        value: 'No',
        selectedText: {
          immigration: 'You\'ll find Canada has great employment opportunities',
          work: 'You\'ll find Canada has great employment opportunities',
          tourist: 'You might lose your heart on Canada and end up working here!',
          study: 'You\'ll find Canada has great part-time work opportunities!',
        }
      },
      {
        title: 'Yes, I am!',
        icon: '../../../assets/images/yes.png',
        iconActive: '../../../assets/images/yes_color.png',
        iconText: null,
        value: 'yes',
        selectedText: {
          immigration: 'That\'s great! Just dont work too hard!',
          work: 'That always looks good when applying for a work Visa',
          tourist: 'Good - Remember to save some money for your trip to Canada',
          study: 'That\'s great! So you can fund your own studies!',
        }
      }
    ]
  };

  public job = {
    name: 'job',
    error: null,
    selectedText: {
      immigration: '',
      work: '',
      tourist: 'That\'s amazing - now we know why you need a holiday',
      study: '',
    }
  };

  public earn = {
    name: 'earn',
    error: null,
    list: [
      {
        title: '0 - 1 000 USD',
        value: '0-1000',
        selectedText: 'Sorry for asking, but we have to know if you can sustain yourself in Canada.'
      },
      {
        title: '1 000 - 2 500 USD',
        value: '1000-2500',
        selectedText: 'Sorry for asking, but we have to know if you can sustain yourself in Canada.'
      },
      {
        title: '2 500 - 5 000 USD',
        value: '2500-5000',
        selectedText: 'Sorry for asking, but we have to know if you can sustain yourself in Canada.'
      },
      {
        title: 'More than 5 000 USD',
        value: '5000+',
        selectedText: 'Sorry for asking, but we have to know if you can sustain yourself in Canada.'
      },
    ]
  };

  public yearsWork = {
    name: 'yearsWork',
    error: null,
    list: [
      {
        // title: 'Good start',
        icon: null,
        iconText: '1-2',
        value: '1',
        selectedText: {
          immigration: 'Everyone has to start somewhere',
          work: 'Everyone has to start somewhere',
          tourist: 'You must be learning a lot.',
          study: 'Everyone has to start somewhere',
        }
      },
      {
        // title: 'Nice',
        icon: null,
        iconText: '3-4',
        value: '2',
        selectedText: {
          immigration: 'A solid base',
          work: 'A solid base, and should be able to secure a job in Canada',
          tourist: 'You probably know your way around by now.',
          study: 'A solid base, and should be able to secure a job in Canada',
        }
      },
      {
        // title: 'Impressive',
        icon: null,
        iconText: '5-6',
        value: '3',
        selectedText: {
          immigration: 'You would easily find a job in Canada',
          work: 'You would easily find a job in Canada',
          tourist: 'You must be very valuable to your employer.',
          study: 'You would easily find a job in Canada',
        }
      },
      {
        // title: 'Wow!',
        icon: null,
        iconText: '6+',
        value: '4',
        selectedText: {
          immigration: 'That\'s impressive. Canada would be glad to have you',
          work: 'That\'s impressive. Canada would be glad to have you',
          tourist: 'Your experience is impressive.',
          study: 'That\'s impressive. Canada would be glad to have you',
        }
      }
    ]
  };

  public info = {
    employed: null,
    job: null,
    yearsWork: null,
    earn: null,
  };

  private subs: any;

  constructor(
      private stepsService: StepsService,
      private router: Router
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  public setValue(event): void {
    this.info[event.field] = event.value;
  }

  public submit(): void {
    let valid = true;
    Object.keys(this.info).forEach((item) => {
      if (!this.info[item]) {
        valid = false;
        this[item].error = 'Field is required!';
      } else {
        this[item].error = null;
      }
    });

    if (valid) {
      this.loader = true;
      this.subs = this.stepsService.send('three', this.info).subscribe(() => {
        this.loader = false;
        this.router.navigate(['/thank-you']);
      });
    }
  }



}
