import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public isMobile = window.innerWidth < 768;

  public social = [
    {
      link: 'https://www.facebook.com/CanadianVisaOrg',
      icon: '../../../assets/images/social/Facebook.png',
      alt: 'facebook'
    },
    {
      link: 'https://twitter.com/canadianvisaorg',
      icon: '../../../assets/images/social/Twitter.png',
      alt: 'twitter'
    },
    {
      link: 'https://www.instagram.com/canadianvisadotorg/',
      icon: '../../../assets/images/social/Instagram.png',
      alt: 'instagram'
    },
  ];

  constructor() { }

  ngOnInit() {
  }

}
