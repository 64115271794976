import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EducationComponent} from './education.component';
import {ButtonSelectModule} from '../../components/button-select/button-select.module';
import {InputSelectModule} from '../../components/input-select/input-select.module';
import {LoaderModule} from '../../components/loader/loader.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        EducationComponent
    ],
    imports: [
        CommonModule,
        ButtonSelectModule,
        InputSelectModule,
        LoaderModule,
        TranslateModule,
    ],
    exports:[
        EducationComponent
    ]
})
export class EducationModule{
}
