import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { countries } from '../../../helpers/countries';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss']
})
export class CountrySelectComponent implements OnInit {

  @Input() error: string;
  @Output() answer = new EventEmitter<any>();

  public selected: string;
  public selectedText: string;

  public countriesList = countries;

  constructor(
      private store: Store<any>
  ) { }

  ngOnInit() {
  }

  private messageText(country) {
    let type = null;
    this.store.select('visaType').subscribe(u => {
      type = u;
    });
    switch (type.name) {
      case 'immigration':
        this.selectedText = `Great! We have many people who apply from`;
        break;
      case 'work':
        this.selectedText = `Great! We have many people who apply from`;
        break;
      case 'tourist':
        this.selectedText = `Nice! We like people from`;
        break;
      case 'study':
        this.selectedText = `Nice! We like people from`;
        break;
      default:
        this.selectedText = `Nice! We like people from`;
    }
  }

  public select(): void {
    this.messageText(this.selected);

    this.answer.emit({
      field: 'nationality',
      value: this.selected
    });
  }

}
