import { Component, OnInit,  Input, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-button-select',
  templateUrl: './button-select.component.html',
  styleUrls: ['./button-select.component.scss'],
})
export class ButtonSelectComponent implements OnInit {

  @Input() classname: string = '';
  @Input() question: any;
  @Input() animation: boolean = true;
  @Input() rows: string | number = 1;

  @Output() answer = new EventEmitter<any>();

  public selected: any;

  private storeValue: any;

  constructor(
      private store: Store<any>,
      private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.storeValue = this.localStorageService.get(this.question.name);
    if (this.storeValue) {
      this.question.list.map(item => {
        if (this.storeValue === item.value) {
          this.Select(item);
        }
      });
    }
  }

  public setIcon(button): string {
    return (button.value === (this.selected && this.selected.value)) ? button.iconActive : button.icon;
  }

  get messageText() {
    if (this.selected && this.selected.selectedText) {
      let type = null;
      this.store.select('visaType').subscribe(u => {
       type = u;
      });
      if (typeof this.selected.selectedText === 'object') {
        return this.selected.selectedText[type.name];
      } else {
        return this.selected.selectedText;
      }
    }

    return false;
  }

  public Select(variant): void {
    this.selected = variant;

    if (this.question.name === 'visa_type') {
      this.localStorageService.set(this.question.name, {id: variant.value, name: variant.title.toLowerCase()});
    } else {
      this.localStorageService.set(this.question.name, variant.value);
    }
    this.answer.emit({
      field: this.question.name,
      value: variant.value
    });
  }
}
