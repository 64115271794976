import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login.component';
import {LoaderModule} from '../../components/loader/loader.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        LoginComponent
    ],
    imports: [
        CommonModule,
        LoaderModule,
        TranslateModule
    ],
    exports:[
        LoginComponent
    ]
})
export class LoginModule{
}
