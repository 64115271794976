import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public social = [
    {
      link: 'https://www.facebook.com/CanadianVisaOrg',
      icon: '../../../assets/images/social/Facebook-1.png',
      alt: 'facebook'
    },
    {
      link: 'https://twitter.com/canadianvisaorg',
      icon: '../../../assets/images/social/Twitter-1.png',
      alt: 'twitter'
    },
    {
      link: 'https://www.instagram.com/canadianvisadotorg/',
      icon: '../../../assets/images/social/Instagram-1.png',
      alt: 'instagram'
    },
  ];

  constructor() { }

  ngOnInit() {
  }

}
