import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TextAnimationComponent} from './text-animation.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        TextAnimationComponent
    ],
    imports: [
        CommonModule,
        TranslateModule
    ],
    exports:[
        TextAnimationComponent
    ]
})
export class TextAnimationModule {
}
