import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable({
  providedIn: 'root'
})
export class StepsService {

  constructor(
      private api: ApiService,
      private local: LocalStorageService
  ) { }

  private convertToFormData(model: any, form: FormData = null, namespace = ''): FormData {
    let formData = form || new FormData();
    let formKey;

    for (let propertyName in model) {
      if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
      let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      if (model[propertyName] instanceof Date)
        formData.append(formKey, model[propertyName].toISOString());
      else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`;
          this.convertToFormData(element, formData, tempFormKey);
        });
      }
      else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File))
        this.convertToFormData(model[propertyName], formData, formKey);
      else
        formData.append(formKey, model[propertyName].toString());
    }
    return formData;
  }

  private setAuth(data?): FormData {
    const newData = Object.assign({}, data, {auth_key: this.local.get('auth_key')});
    return this.convertToFormData(newData);
  }

  public send(step, data): Observable<any> {
    return this.api.post(`/v1/api/step-${step}`, {
      body: this.setAuth(data),
      params: {
        'Content-Type': 'application/json'
      }
    });
  }

  public autoLogin(data: any): Observable<any> {
    return this.api.post(`/v1/api/autologin`, {
      body: this.convertToFormData(data),
    });
  }

  public gatProduct(): Observable<any> {
    return this.api.get(`/v1/api/get-products?type=2`, {});
  }

  public saveProduct(data: any): Observable<any> {
    return this.api.post(`/v1/api/step-product`, {
      body: this.setAuth(data),
      params: {
        'Content-Type': 'application/json'
      }
    });
  }
}
