import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {AuthService} from './services/auth.service';

import {DefaultComponent} from './layouts/default/default.component';
import {EmptyComponent} from './layouts/empty/empty.component';

import {WelcomeComponent} from './routes/welcome/welcome.component';
import {AboutComponent} from './routes/about/about.component';
import {EducationComponent} from './routes/education/education.component';
import {ThankYouComponent} from './routes/thank-you/thank-you.component';
import {EmploymentComponent} from './routes/employment/employment.component';
import {LoginComponent} from './routes/login/login.component';

const routes: Routes = [
  {
    path: '', component: DefaultComponent, canActivate: [AuthService], children: [
      {path: '', data: {last_step: 'Step One' }, pathMatch: 'full', component: WelcomeComponent},
      {path: 'about', data: {last_step: 'Step Two' }, pathMatch: 'full', component: AboutComponent},
      {path: 'education', data: {last_step: 'Step Three' }, pathMatch: 'full', component: EducationComponent},
      {path: 'employment', data: {last_step: 'Step Four' }, pathMatch: 'full', component: EmploymentComponent},
      {path: 'thank-you', pathMatch: 'full', component: ThankYouComponent},
    ]
  },
  {
    path: '', component: EmptyComponent, children: [
      {path: 'auto-login', data: {last_step: 'Auto Login' },  pathMatch: 'full', component: LoginComponent},
    ]
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
