import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AboutComponent} from './about.component';
import {ButtonSelectModule} from '../../components/button-select/button-select.module';
import {InputSelectModule} from '../../components/input-select/input-select.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { BirthdaySelectModule } from './birthday-select/birthday-select.module';
import {LoaderModule} from '../../components/loader/loader.module';
import {CountrySelectModule} from './country-select/country-select.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        AboutComponent
    ],
    imports: [
        CommonModule,
        ButtonSelectModule,
        InputSelectModule,
        BrowserAnimationsModule,
        BirthdaySelectModule,
        LoaderModule,
        TranslateModule,
        CountrySelectModule
    ],
    exports:[
        AboutComponent
    ]
})
export class AboutModule {
}
