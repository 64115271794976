import { Component, OnInit, OnDestroy } from '@angular/core';
import { StepsService } from '../../services/steps/steps.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit, OnDestroy {

  private subs: any;

  public loader = false;

  public completeSchool = {
    name: 'completeSchool',
    error: null,
    list: [
      {
        title: 'Not yet',
        icon: '../../../assets/images/no.png',
        iconActive: '../../../assets/images/no_color.png',
        iconText: null,
        value: 'No',
        selectedText: {
          immigration: 'Did you know Canada has one of the best education systems in the world?',
          work: 'You would need to finish high school before you can get a work visa!',
          tourist: '',
          study: 'You would need to finish high school before you can get a student visa.',
        }
      },
      {
        title: 'Yes, I did!',
        icon: '../../../assets/images/yes.png',
        iconActive: '../../../assets/images/yes_color.png',
        iconText: null,
        value: 'yes',
        selectedText: {
          immigration: 'Great - You should know that you can always continue your studies in Canada',
          work: 'Great - You are one step closer to starting a career in Canada',
          tourist: '',
          study: 'You are one step closer to studying in Canada',
        }
      }
    ]
  }

  public furtherEducation = {
    name: 'furtherEducation',
    error: null,
    list: [
      {
        title: 'Not yet',
        icon: '../../../assets/images/no.png',
        iconActive: '../../../assets/images/no_color.png',
        iconText: null,
        value: 'No',
        selectedText: {
          immigration: 'Did you know Canada has some of the best universities in the world?',
          work: 'Dont worry, there are a lot of job opportunities for unskilled workers in Canada',
          tourist: '',
          study: 'You are in the perfect place - Canada has some of the best universities in the world.',
        }
      },
      {
        title: 'Yes, I have!',
        icon: '../../../assets/images/yes.png',
        iconActive: '../../../assets/images/yes_color.png',
        iconText: null,
        value: 'yes',
        selectedText: {
          immigration: 'That is great - formal education will increase your chances of securing Canadian residence.',
          work: 'That is great - formal education will increase your chances of securing work in Canada',
          tourist: '',
          study: 'So you are looking to further your studies - Canada is a great choice!',
        }
      }
    ]
  };

  public english = {
    name: 'english',
    error: null,
    list: [
      {
        title: 'Very poor',
        icon: '../../../assets/images/poor.png',
        iconActive: '../../../assets/images/poor_color.png',
        iconText: null,
        value: 'poor',
        selectedText: {
          immigration: 'Don\'t worry, You\'ll learn.',
          work: 'Don\'t worry, You\'ll learn.',
          tourist: '',
          study: 'Don\'t worry, You\'ll learn.',
        }
      },
      {
        title: 'Basic',
        icon: '../../../assets/images/basic.png',
        iconActive: '../../../assets/images/basic_color.png',
        iconText: null,
        value: 'basic',
        selectedText: {
          immigration: 'That\'s a good place to start from.',
          work: 'That\'s a good place to start from.',
          tourist: '',
          study: 'That\'s a good place to start from.',
        }
      },
      {
        title: 'Moderate',
        icon: '../../../assets/images/medium.png',
        iconActive: '../../../assets/images/medium_color.png',
        iconText: null,
        value: 'moderate',
        selectedText: {
          immigration: 'Great, that will help you to settle in faster',
          work: 'Great, that will help you to settle in faster',
          tourist: '',
          study: 'Great, that will help you to settle in faster',
        }
      },
      {
        title: 'Proficient',
        icon: '../../../assets/images/high.png',
        iconActive: '../../../assets/images/high_color.png',
        iconText: null,
        value: 'proficient',
        selectedText: {
          immigration: 'Excellent, that will improve your chances for immigration.',
          work: 'Excellent, that will improve your chances for getting a visa.',
          tourist: '',
          study: 'Excellent, that will improve your chances for getting a visa.',
        }
      },
    ]
  };

  public french = {
    name: 'french',
    error: null,
    list: [
      {
        title: 'Very poor',
        icon: '../../../assets/images/poor.png',
        iconActive: '../../../assets/images/poor_color.png',
        iconText: null,
        value: 'poor',
        selectedText: {
          immigration: 'Don\'t worry, You\'ll learn.',
          work: 'Don\'t worry, You will pick some up along the way.',
          tourist: '',
          study: 'Don\'t worry, You will pick some up along the way.',
        }
      },
      {
        title: 'Basic',
        icon: '../../../assets/images/basic.png',
        iconActive: '../../../assets/images/basic_color.png',
        iconText: null,
        value: 'basic',
        selectedText: {
          immigration: 'That\'s a good place to start from.',
          work: 'That\'s a good place to start from.',
          tourist: '',
          study: 'That\'s a good place to start from.',
        }
      },
      {
        title: 'Moderate',
        icon: '../../../assets/images/medium.png',
        iconActive: '../../../assets/images/medium_color.png',
        iconText: null,
        value: 'moderate',
        selectedText: {
          immigration: 'Great, that will help you to settle in faster',
          work: 'Great, that will help you to settle in faster',
          tourist: '',
          study: 'Great, that will help you to settle in faster',
        }
      },
      {
        title: 'Proficient',
        icon: '../../../assets/images/high.png',
        iconActive: '../../../assets/images/high_color.png',
        iconText: null,
        value: 'proficient',
        selectedText: {
          immigration: 'Excellent, cela améliorera vos chances d\'immigration.',
          work: 'Excellent, cela améliorera vos chances d\'obtenir un visa.',
          tourist: '',
          study: 'Excellent, cela améliorera vos chances d\'obtenir un visa.',
        }
      },
    ]
  };

  public info = {
    completeSchool: null,
    furtherEducation: null,
    english: null
  };

  constructor(
      private stepsService: StepsService,
      private router: Router,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  public setValue(event): void {
    this.info[event.field] = event.value;
  }

  public submit(): void {
    let valid = true;
    Object.keys(this.info).forEach((item) => {
      if (this.info[item] === null) {
        valid = false;
        this[item].error = 'Field is required!';
      } else if (this[item]) {
        this[item].error = null;
      }
    });

    if (valid) {
      this.loader = true;
      this.subs = this.stepsService.send('two', this.info).subscribe(() => {
        this.loader = false;
        this.router.navigate(['/employment']);
      });
    }
  }

}
