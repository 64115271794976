import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit, OnDestroy {

  public subs: any;
  public steps = {
    step: 0,
    title: '',
    subtitle: '',
  };

  public showEducation: any;

  constructor(
      private store: Store<any>,
      private router: Router
  ) { }

  ngOnInit() {
    this.setStep(window.location.pathname);

    this.subs = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.setStep(e.url);
      }
    });

    this.store.select('visaType').subscribe(u => {
      if (u.id) {
        this.showEducation = u.id !== 10;
        return;
      }
      this.showEducation = true;
    });
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  private setStep(url): void {
    switch (url) {
      case '/':
        this.steps = {
          step: 1,
          title: 'Welcome, your journey starts here.',
          subtitle: null
        };
        break;
      case '/about':
        this.steps = {
          step: 2,
          title: 'Tell us about yourself',
          subtitle: 'Help us evaluate your visa requirements',
        };
        break;
      case '/education':
        this.steps = {
          step: 3,
          title: 'Tell us about your education',
          subtitle: 'Help us evaluate your visa requirements',
        };
        break;
      case '/employment':
        this.steps = {
          step: 4,
          title: 'Tell us about your job',
          subtitle: 'Help us evaluate your visa requirements',
        };
        break;
      case '/thank-you':
        this.steps = {
          step: 5,
          title: 'Thanks. What’s next?',
          subtitle: null,
        };
        break;
    }
  }

}
