import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StepsService} from '../../services/steps/steps.service';
import {LocalStorageService} from 'angular-2-local-storage';
import {Store} from '@ngrx/store';
import * as VisaActions from '../../actions/visa-type.action';
import {TranslateService} from '@ngx-translate/core';
import {ExponeaService} from '../../services/exponea/exponea.service';
import { EventsService } from 'src/app/services/events/events.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private campaignName = 'CV Combined Funnel v1';

  public loader = true;

  private visaType: any;

  private productTypes = [8, 9, 10, 11]

  constructor(
      private store: Store<any>,
      private router: Router,
      private route: ActivatedRoute,
      private stepsService: StepsService,
      private localStorageService: LocalStorageService,
      private translateService: TranslateService,
      private exponea: ExponeaService,
      private events: EventsService
  ) { }

  private setVisaType(id: number): void {
    let name: string;
    switch (id) {
      case 8:
        name = 'study';
        break;
      case 9:
        name = 'work';
        break;
      case 10:
        name = 'tourist';
        break;
      case 11:
        name = 'immigration';
        break;
      default:
        name = 'work';
    }

    this.visaType = {
      name,
      id
    };

    this.store.dispatch(new VisaActions.AddVisa(this.visaType));
    this.localStorageService.set('visa_type', this.visaType);
  }

  ngOnInit() {
    // this.store.select('visaType').subscribe(u => {
    //   this.visaType = u;
    // })
    const lang = this.translateService.currentLang;
    if (lang === 'es') {
      this.campaignName = 'CV Combined Funnel - ES';
    }

    const hash = this.route.snapshot.queryParams.hash;

    const loginData = {
      auth_key: hash,
      sourceCampaign: this.campaignName,
      hash
    };

    const contactStatus = this.route.snapshot.queryParams.contact_status;
    if (contactStatus) {
      loginData['contact_status'] = contactStatus;
    }

    this.localStorageService.set('auth_key', hash);


    this.stepsService.autoLogin(loginData).subscribe((res) => {
      if (res.status) {
        this.loader = false;
        const step = res.data.step;

        if (res.data.uuid) {
          this.exponea.update(res.data.uuid);
        }

        localStorage.setItem('account_number', res.data.evaluation.account_number);
        localStorage.setItem('email', res.data.evaluation.email);

        this.events.setUserAfterAutoLogin(hash);

        this.events.pageVisit();

        this.setVisaType(res.data.evaluation.productId);

        if (step === 1) {
          if (this.productTypes.indexOf(res.data.evaluation.productId) !== -1) {
            this.router.navigate(['/about']);
          } else {
            this.router.navigate(['/']);
          }
        } else if (step === 2) {
          if (this.visaType.id === 10) {
            this.router.navigate(['/employment']);
          } else {
            this.router.navigate(['/education']);
          }
        } else if (step === 3) {
          this.router.navigate(['/employment']);
        } else if (step >= 4) {
          this.router.navigate(['/thank-you']);
        } else {
          this.router.navigate(['/']);
        }
      } else {
        window.location.href = 'https://canadianvisa.org/thank-you';
      }
    }, error => {
      window.location.href = 'https://canadianvisa.org/thank-you';
    });
  }
}
