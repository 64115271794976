import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { trigger, transition, animate, style, state} from '@angular/animations';

@Component({
  selector: 'app-text-animation',
  templateUrl: './text-animation.component.html',
  styleUrls: ['./text-animation.component.scss'],
  animations: [
    trigger('slideInLeft', [
      state('in', style({opacity: 1, transform: 'translate3d(0, 0, 0)'})),
      state('out', style({opacity: 0})),
      transition('out => in', [
        style({
          opacity: 0,
          transform: 'translate3d(-50%, 0, 0)'
        }),
        animate('500ms ease-in')
      ]),
      transition('in => out', [
          style({
            opacity: 0,
          }),
          animate('0ms', )
      ])
    ])
  ]
})
export class TextAnimationComponent implements OnInit, OnChanges {

  @Input() text: string;
  @Input() value: string;

  public animate = true;

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: any) {
    if(changes.text && changes.text.previousValue !== changes.text.currentValue || changes.value && changes.value.previousValue !== changes.value.currentValue){
      this.animate = false;
      setTimeout(() => {
        this.animate = true;
      }, 100);
    }
  }

}
