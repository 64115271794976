import { Component, OnInit } from '@angular/core';
import {Router, NavigationEnd, ActivationEnd, ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {LocalStorageService} from 'angular-2-local-storage';
import * as VisaActions from './actions/visa-type.action';
import {TranslateService} from "@ngx-translate/core";
import {ExponeaService} from "./services/exponea/exponea.service";
import {getParameterByName} from "./helpers/getParameterByName";
import {Cookies} from "./helpers/Cookies";
import { EventsService } from './services/events/events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  title = 'CanadianVisa';

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private translate: TranslateService,
      private localStorageService: LocalStorageService,
      private exponea: ExponeaService,
      private store: Store<any>,
      private events: EventsService
  ) {
    this.initExponea();
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (evt instanceof ActivationEnd && evt.snapshot.queryParams.lang) {
        const lang = evt.snapshot.queryParams.lang;
        this.translate.use(lang);
        this.localStorageService.set('lang', lang);
      }

      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.setEventsLastStep();

      if(!window.location.href.includes('/auto-login')){
        this.events.pageVisit();
      }
      this.exponeaPageView(evt);

      window.scrollTo({top: 0, behavior: 'smooth'});
    });

    this.setVisaType();

    this.setLang();
  }

  private initExponea(): void {
    const UUID = getParameterByName('uuid') || Cookies.get('UUID');

    let coustomerData = {};

    if (UUID) {
      Cookies.set('UUID', UUID);

      coustomerData = {
        customer: {
          registered: UUID
        }
      };
    }

    this.exponea.init(coustomerData);
  }

  private setEventsLastStep(): void{
    const data = this.getLastActivatedRouteData();

    const value = (data && data['last_step']) ? data['last_step'] : null;
    this.events.lastStep.next(value);
  }

  private getLastActivatedRouteData(): any {
    let route = this.route;

    while (route.firstChild) {
      if (route && route.firstChild) {
        route = route.firstChild
      }
    }

    return route.snapshot.data;
  }

  private exponeaPageView(evt): void {
    let status = '';

    switch (evt.url) {
      case '/':
        status = 'Step One';
        break;
      case '/about':
        status = 'Step Two';
        break;
      case '/education':
        status = 'Step Three';
        break;
      case '/employment':
        status = 'Step Four';
        break;
      default:
        status = '';
        break;
    }

    this.exponea.track('page_visit', {
      title: document.title,
      funnel_status_reason: status
    });
  }

  private setVisaType(){
    const localStore = this.localStorageService.get('visa_type');
    if (localStore) {
      this.store.dispatch(new VisaActions.AddVisa(localStore));
    }
  }

  private setLang(): void {
    const lang: any = this.localStorageService.get('lang');
    const hasLang = window.location.href.indexOf('auto-login') > 0;
    if (lang && !hasLang) {
        this.translate.use(lang);
    }
  }
}
