import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { BirthdaySelectComponent } from './birthday-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import {TextAnimationModule} from '../../../components/text-animation/text-animation.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        BirthdaySelectComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        NgSelectModule,
        FormsModule,
        TextAnimationModule
    ],
    exports:[
        BirthdaySelectComponent
    ]
})
export class BirthdaySelectModule {
}
