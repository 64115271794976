import { Component, OnInit, OnDestroy } from '@angular/core';
import {StepsService} from '../../services/steps/steps.service';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {

  private subs: any;

  public loader = false;

  public married = {
    name: 'married',
    error: null,
    list: [
      {
        title: 'No, I’m not.',
        icon: '../../../assets/images/no.png',
        iconActive: '../../../assets/images/no_color.png',
        iconText: null,
        value: 'no',
        selectedText: {
          immigration: '',
          work: '',
          tourist: 'Maybe you find the love of your life on this trip!',
          study: 'Not a problem - allows you to focus on your studies!',
        }
      },
      {
        title: 'Yes, I am!',
        icon: '../../../assets/images/yes.png',
        iconActive: '../../../assets/images/yes_color.png',
        iconText: null,
        value: 'yes',
        selectedText: {
          immigration: '',
          work: '',
          tourist: 'You will share an amazing experience with your significant other',
          study: 'Always good to have a support system!',
        }
      },
    ]
  };

  public gender = {
    name: 'gender',
    error: null,
    list: [
      {
        title: 'Male',
        icon: '../../../assets/images/male.png',
        iconActive: '../../../assets/images/male_color.png',
        iconText: null,
        value: 'Male',
        selectedText: null
      },
      {
        title: 'Other',
        icon: '../../../assets/images/single.png',
        iconActive: '../../../assets/images/single_color.png',
        iconText: null,
        value: 'Other',
        selectedText: null
      },
      {
        title: 'Female',
        icon: '../../../assets/images/female.png',
        iconActive: '../../../assets/images/female_color.png',
        iconText: null,
        value: 'Female',
        selectedText: null
      }
    ]
  };

  public application_type = {
    name: 'application_type',
    error: null,
    list: [
      {
        title: 'Single',
        icon: '../../../assets/images/single.png',
        iconActive: '../../../assets/images/single_color.png',
        iconText: null,
        value: 'single',
        selectedText: {
          immigration: 'You always have to follow your heart - Your heart is not wrong for choosing Canada',
          work: 'You always have to follow your heart - Your heart is not wrong for choosing Canada',
          tourist: 'This way you can spend the time doing what you want to.',
          study: 'You always have to follow your heart - Your heart is not wrong for choosing Canada',
        }
      },
      {
        title: 'Family',
        icon: '../../../assets/images/family.png',
        iconActive: '../../../assets/images/family_color.png',
        iconText: null,
        value: 'family',
        selectedText: {
          immigration: 'Canada is one of the best places in the world to raise your kids',
          work: 'Canada is one of the best places in the world to raise your kids',
          tourist: 'There are few things as magical as traveling with your family',
          study: 'Canada has much to offer you and your family',
        }
      },
    ]
  };

  public birthday = {
    name: 'birthday',
    error: null
  };

  public nationality = {
    name: 'nationality',
    error: null
  };

  private info = {
    nationality: null,
    married: null,
    application_type: null,
    gender: null,
    birthday: null
  };

  constructor(
      private stepsService: StepsService,
      private router: Router,
      private store: Store<any>
  ) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  public setValue(event): void {
    this.info[event.field] = event.value;
  }

  public submit(): void {
    let valid = true;
    Object.keys(this.info).forEach((item) => {
      if (!this.info[item]) {
        valid = false;
        this[item].error = 'Field is required!';
      } else {
        this[item].error = null;
      }

    });

    if (valid) {
      this.loader = true;
      this.subs = this.stepsService.send('one', this.info).subscribe(() => {
        this.loader = false;
        let visaType = null;
        this.store.select('visaType').subscribe(u => {
          visaType = u;
        });
        if (visaType && visaType.id === 10) {
          this.router.navigate(['/employment']);
        } else {
          this.router.navigate(['/education']);
        }
      });
    }
  }

}
