import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ThankYouComponent} from './thank-you.component';
import {TranslateModule} from '@ngx-translate/core';
import { TestimonialsModule } from 'src/app/components/testimonials/testimonials.module';

@NgModule({
    declarations: [
        ThankYouComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        TestimonialsModule
    ],
    exports:[
        ThankYouComponent
    ]
})
export class ThankYouModule{
}
