import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputSelectComponent} from './input-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import {TextAnimationModule} from '../text-animation/text-animation.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        InputSelectComponent
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        TranslateModule,
        TextAnimationModule
    ],
    exports: [
        InputSelectComponent
    ]
})
export class InputSelectModule {
}
