import {Action} from '@ngrx/store';

export enum VisaActionTypes {
    ADD_VISA = '[Visa] add'
}

export class AddVisa implements Action {
    readonly type = VisaActionTypes.ADD_VISA;

    constructor(public payload: any) {}
}

export type VisaTypeAction = AddVisa;
