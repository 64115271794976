import { Component, OnInit, OnDestroy } from '@angular/core';
import {Router} from '@angular/router';
import { StepsService } from '../../services/steps/steps.service';
import {Store} from '@ngrx/store';
import * as VisaActions from '../../actions/visa-type.action';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  public loader = true;

  private subs: any;

  public visa_type = {
    name: 'visa_type',
    error: null,
    list: []
  };

  public info = {
    visa_type: null
  };

  constructor(
      private store: Store<any>,
      private router: Router,
      private stepsService: StepsService
  ) {
    this.stepsService.gatProduct().subscribe((res) => {
      res.forEach((item) => {
          // if (item.name === 'Immigration') return;

          const newType: any = {}
          newType.title = item.name;
          newType.value = item.id;
          newType.selectedText = '';
          
          switch (item.name) {
            case 'Immigration':
              newType.icon = '../../../assets/images/passport.png';
              newType.iconActive = '../../../assets/images/passport_color.png';
              break;
            case 'Work':
              newType.icon = '../../../assets/images/landing-man.png';
              newType.iconActive = '../../../assets/images/landing-man_color.png';
              break;
            case 'Tourist':
              newType.icon = '../../../assets/images/tourist.png';
              newType.iconActive = '../../../assets/images/tourist_color.png';
              break;
            case 'Study':
              newType.icon = '../../../assets/images/education.png';
              newType.iconActive = '../../../assets/images/education_color.png';
              break;
            default:
              newType.icon = '../../../assets/images/education.png';
              newType.iconActive = '../../../assets/images/education_color.png';
          }
          this.visa_type.list.push(newType);
      });

      this.loader = false;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  public setValue(event): void {
    if(event){
      this.info[event.field] = event.value;

      this.visa_type.list.map((item) => {
        if (item.value === event.value) {
          this.store.dispatch(new VisaActions.AddVisa({name: item.title.toLowerCase(), id: item.value}));
        }
      });
    }    
  }

  public submit(): void {
    let valid = true;
    Object.keys(this.info).forEach((item) => {
      if (!this.info[item]) {
        valid = false;
        this[item].error = 'Field is required!';
      } else {
        this[item].error = null;
      }
    });

    if (valid) {
      this.loader = true;
      this.subs = this.stepsService.saveProduct({product_id: this.info.visa_type}).subscribe(() => {
        this.loader = false;
        this.router.navigate(['/about']);
      });
    }
  }

}
