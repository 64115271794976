import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as jwt from 'jwt-encode';
import {Md5} from 'ts-md5/dist/md5';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  public token: any = '';

  constructor(
    private store: Store<any>,
    private translate: TranslateService
  ) { }

  public hideForTourist = false;

  ngOnInit() {
    this.store.select('visaType').subscribe();
    this.store.select('visaType').subscribe(type => {
      this.hideForTourist = type.id === 10;
    });

    this.generateToken();
  }

  public generateToken() {
    const secret = 'CAC8379FBC6BEE5AF5FE6347062AFA0B';

    const data = {
      token: Md5.hashStr(localStorage.getItem('account_number') || ''),
      hash: Md5.hashStr(localStorage.getItem('email') || ''),
      contact: 'Contact Me CallBack Welcome',
      type: 'leads',
      lang: this.translate.currentLang || 'en'
    }

    this.token = jwt(data, secret);
  }
}
