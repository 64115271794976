import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {LocalStorageService} from 'angular-2-local-storage';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent implements OnInit {

  constructor(
      private router: Router,
      private localStorage: LocalStorageService,
      private route: ActivatedRoute
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.localStorage.set('current-step', event.url);
      }
    });
  }

  ngOnInit() {
    const hash = this.route.snapshot.queryParams.hash;
    const localHash = this.localStorage.get('auth_key');
    if ( hash && localHash && hash !== localHash) {
      this.localStorage.clearAll();
    }

    const step = this.localStorage.get('current-step');
    if (!!step) {
      this.router.navigate([step]);
    }
  }

}
