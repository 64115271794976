import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {DefaultComponent} from './default.component';
import {HeaderModule} from '../../components/header/header.module';
import {StepsModule} from '../../components/steps/steps.module';
import {FooterComponent} from '../../components/footer/footer.component';

@NgModule({
    declarations: [
        DefaultComponent,
        FooterComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        HeaderModule,
        StepsModule
    ],
    exports: [
        DefaultComponent
    ]
})
export class DefaultModule {}
