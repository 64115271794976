import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TestimonialsComponent implements OnInit {

  public isMobile: boolean = window.innerWidth < 992;

  public currentSlide: number = 0;

  public items: any[] = [
    {
      video: 'https://cv-media-files.s3.us-west-2.amazonaws.com/video/cv-testimonials/cv_katherine.mp4',
      text: '<b>Meet Katherine Stewart</b> Katherine has successfully immigrated to Canada from the UK through the Express Entry System with the help of our amazing team of RCICs.',
      poster: '/assets/images/testimonials/katherine.png'
    },
    {
      video: 'https://cv-media-files.s3.us-west-2.amazonaws.com/video/cv-testimonials/cv_solange.mp4',
      text: '<b>Meet Solange Diamond</b> who immigrated to Canada from the Ivory Coast with the assistance and support of our professional RCICs. She managed to receive her study visa and is thrilled to be a part of Canadian society. See how we can help you immigrate to the Great White North by visiting Canadianvisa.org',
      poster: '/assets/images/testimonials/solange.png'
    },
    {
      video: 'https://cv-media-files.s3.us-west-2.amazonaws.com/video/cv-testimonials/cv_ela.mp4',
      text: '<b>Ela Doria</b> came to Canada from Mexico on a study visa, and also managed to apply for a work permit in order to earn an income in her new city. This has gone a long way to support herself and her family. Our professional RCICs were with her every step of the way and ensured she understood the entire process and had the right documents on hand to avoid delays.',
      poster: '/assets/images/testimonials/eladoria.png'
    }
  ]

  public customOptions: OwlOptions = {
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoHeight: false,
    nav: false,
    items: 3,
    responsive: {
      0: {
        items: 1,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
      },
      768: {
        items: 3,
      }
    },
  }

  constructor() { }

  ngOnInit(): void {
  }

  public slideChanged(event: SlidesOutputData): void {
    this.currentSlide = event.startPosition;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    this.isMobile = event.target.innerWidth < 992;
  }
}
