import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StepsComponent} from './steps.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        StepsComponent
    ],
    imports: [
        CommonModule,
        TranslateModule
    ],
    exports:[
        StepsComponent
    ]
})
export class StepsModule{
}
