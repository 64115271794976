import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { CountrySelectComponent } from './country-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import {TextAnimationModule} from '../../../components/text-animation/text-animation.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        CountrySelectComponent
    ],
    imports: [
        CommonModule,
        NgSelectModule,
        FormsModule,
        TextAnimationModule,
        TranslateModule
    ],
    exports:[
        CountrySelectComponent
    ]
})
export class CountrySelectModule{
}
