import {Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss']
})
export class InputSelectComponent implements OnInit {

  @Input() question: any;
  @Output() answer = new EventEmitter<any>();

  public selected: any;

  private storeValue: any;

  public inputValue = '';

  constructor(
      private store: Store<any>,
      private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.storeValue = this.localStorageService.get(this.question.name);
    if (this.storeValue) {
      this.inputValue = this.storeValue
      if (!this.question.list) {
        this.select(Object.assign({}, this.question, {value: this.storeValue}));
        return;
      }
      this.question.list.map(item => {
        if (this.storeValue === item.value) {
          this.select(item);
        }
      });
    }
  }

  get messageText() {
    if (this.selected && this.selected.selectedText) {
      let type = null;
      this.store.select('visaType').subscribe(u => {
        type = u;
      });
      if (typeof this.selected.selectedText === 'object') {
        return this.selected.selectedText[type.name];
      } else {
        return this.selected.selectedText;
      }
    }
  }

  public select(event): void {
    if (this.question.list && this.question.list.length > 0) {
      this.selected = event;
    } else {
      this.selected = { value: event.value };
    }

    // @ts-ignore
    if (!this.selected.selectedText) {
      // @ts-ignore
      this.selected.selectedText =  this.question.selectedText;
    }

    this.localStorageService.set(this.question.name, event.value);

    this.answer.emit({
      field: this.question.name,
      value: event.value
    });
  }
}
