import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CountrySelectModule } from './routes/about/country-select/country-select.module';
import { ButtonSelectModule } from './components/button-select/button-select.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {InputSelectModule } from './components/input-select/input-select.module';
import { TextAnimationModule } from './components/text-animation/text-animation.module';
import { LoaderModule } from './components/loader/loader.module';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { LocalStorageModule } from 'angular-2-local-storage';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import {BirthdaySelectModule} from './routes/about/birthday-select/birthday-select.module';
import {DefaultModule} from './layouts/default/default.module';
import {EmptyModule} from './layouts/empty/empty.module';
import {LoginModule} from './routes/login/login.module';
import { ThankYouModule } from './routes/thank-you/thank-you.module';
import { EmploymentModule } from './routes/employment/employment.module';
import { WelcomeModule } from './routes/welcome/welcome.module';
import { AboutModule } from './routes/about/about.module';
import { EducationModule } from './routes/education/education.module';
import {StoreModule} from '@ngrx/store';
import {VisaTypeReducer} from './reducers/visa-type.reducer';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        CountrySelectModule,
        ButtonSelectModule,
        BrowserAnimationsModule,
        InputSelectModule,
        TextAnimationModule,
        LoaderModule,
        LocalStorageModule.forRoot({
            prefix: 'mdc',
            storageType: 'localStorage'
        }),
        StoreModule.forRoot({
            visaType: VisaTypeReducer
        }),
        NgSelectModule,
        FormsModule,
        BirthdaySelectModule,
        DefaultModule,
        EmptyModule,
        LoginModule,
        ThankYouModule,
        EmploymentModule,
        WelcomeModule,
        AboutModule,
        EducationModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http, 'assets/lang/', '.json'),
                deps: [HttpClient]
            }
        })
    ],
    providers: [],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
