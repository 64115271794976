import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {LocalStorageService} from 'angular-2-local-storage';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {

  private authKey;

  constructor(
      private router: Router,
      private localStorageService: LocalStorageService
  ) {
    this.authKey = this.localStorageService.get('auth_key');
  }

  canActivate() {
    const auth = !!this.authKey;
    if (!auth) {
      window.location.href = 'https://canadianvisa.org';
    }
    return auth;
  }
}
