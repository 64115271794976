import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {Cookies} from "../../helpers/Cookies";

declare global {
  interface Window { exponea: any; }
}

@Injectable({
  providedIn: 'root'
})
export class ExponeaService {

  public init(coustomerData: any = {}): void {
    if (environment.production) {
      window.exponea.start({
        target: 'https://api.exponea.com',
        token: '9a5c1884-6c59-11eb-8b86-9ef8a0068af9',
        utm_always: true,
        track: {
          activity: true
        },
        ...coustomerData
      });
    }
  }

  public update(uuid: string): void {
    if (environment.production) {
      window.exponea.identify({'registered': uuid});

      Cookies.set('UUID', uuid);
    }
  }

  public track(id: string, action: any = {}): void {
    if (environment.production) {
      window.exponea.track(id, action);
    }
  }
}
