import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare global {
  interface Window { exponea: any; cve: any}
}

interface UserInfo {
  session_id: string,
  hash: string,
  _ga: string
}

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  private readonly USER_STORAGE_ACCESSOR: string = 'cve_user';

  private readonly DEFAULT_CATEGORY: string = 'funnel_cv_combined';

  public category: BehaviorSubject<string> = new BehaviorSubject(this.DEFAULT_CATEGORY);

  public lastStep: BehaviorSubject<string> = new BehaviorSubject('');

  public user: UserInfo = {} as UserInfo;

  constructor() { }

  public pageVisit() {
    this.getUser();
    this.initSession();
    this.sendIdentity();
    this.sendPageVisit();
  }

  private sendIdentity(): void {
    if (!(this.user && this.user.hash)) { return; }
    window['cve']('identity', this.user.hash)
  }

  public setUserAfterAutoLogin(hash: string): void {
    this.user = {} as UserInfo;
    this.user.hash = hash;
    this.setUser();
  }

  public getUser(): void {
    let user = {};
    let userLs = localStorage.getItem(this.USER_STORAGE_ACCESSOR) || '';

    if (user) {
      try {
        user = JSON.parse(userLs) || {};
      } catch (e) {
        user = {};
      };
    }
    else {
      user = {};
    }

    this.user = user as UserInfo;
  }

  public initSession(): void {
    const sessionId = sessionStorage.getItem('cve_session');
    if (sessionId) {
      this.user.session_id = sessionId;
    }
    else {
      this.user.session_id = this.generateSessionId();
      sessionStorage.setItem('cve_session', this.user.session_id)
    }

    const gaClientId = this.getCookieByName('_ga');
    if(gaClientId) {
      this.user._ga = gaClientId;
    }
  }

  sendPageVisit() {
    let info: any = {
      session_id: this.user.session_id,
      _ga: this.user._ga
    };

    if (this.lastStep.value) {
      info['last_step'] = this.lastStep.value
    }

    window['cve']('track', this.category.value, 'page_visit', info);
  }

  getCookieByName(cname: string): string {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return '';
  }

  private setUser() {
    localStorage.setItem(this.USER_STORAGE_ACCESSOR, JSON.stringify(this.user))
  }

  private generateSessionId(): string {
    return String(
      Date.now().toString(32) +
      Math.random().toString(16)
    ).replace(/\./g, '')
  }
}
