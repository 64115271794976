import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonSelectComponent} from './button-select.component';
import {TextAnimationModule} from '../text-animation/text-animation.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [
        ButtonSelectComponent
    ],
    imports: [
        CommonModule,
        TextAnimationModule,
        TranslateModule
    ],
    exports:[
        ButtonSelectComponent
    ]
})
export class ButtonSelectModule {
}
