import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import {LocalStorageService} from 'angular-2-local-storage';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-birthday-select',
  templateUrl: './birthday-select.component.html',
  styleUrls: ['./birthday-select.component.scss']
})
export class BirthdaySelectComponent implements OnInit {

  constructor(
      private store: Store<any>,
      private localStorageService: LocalStorageService
  ) {
    const years = [];
    for (let i = (new Date().getFullYear() - 17) - 1; i > new Date().getFullYear() - 53; i--) {
      years.push(i);
    }

    this.yearRange = years;
  }

  @Output() answer = new EventEmitter<any>();
  @Input() error: string;

  public dayRange = this.range(1, 31);
  public monthRange = this.range(1, 12);
  public yearRange = [];

  public day = null;
  public month = null;
  public year = null;

  public selectedText = null;

  private text = {
    0: {
      immigration: '',
      work: '',
      tourist: 'That is the perfect age to go traveling!',
      study: '',
    },
    18: {
      immigration: 'Canada offers a wealth of oppotunities for young people. You are making a good decision',
      work: 'Canada is one of the best places to start your career!',
      tourist: 'That is the perfect age to go traveling!',
      study: 'You can not go wrong with studying in Canada!',
    },
    25: {
      immigration: 'That is the perfect age to move to Canada',
      work: 'The job market is looking for people in around your age!',
      tourist: 'That is the perfect age to go traveling!',
      study: 'Canada has so much to offer you!',
    },
    40: {
      immigration: 'Canada is the perfect place to settle down and even retire!',
      work: 'Canada is the perfect place to settle down and even retire!',
      tourist: 'That is the perfect age to go traveling!',
      study: 'One should never stop learning and growing!',
    }
  };

  public range(start, stop) {
    // @ts-ignore
    return new Array((stop - start) + 1).fill().map((d, i) => i + start);
  }

  ngOnInit() {
  }

  private messageText(age) {
    let type = null;
    this.store.select('visaType').subscribe(u => {
      type = u;
    });
    if (type) {
      this.selectedText = this.text[age][type.name];
    }
  }

  public select(): void {
    if(this.day && this.month && this.year) {
      const age = new Date().getFullYear() - this.year;
      if (age >= 18 && age < 25) {
        this.messageText(18);
      } else if (age >= 25 && age < 40) {
        this.messageText(25);
      } else if (age >= 40) {
        this.messageText(40);
      } else {
        this.messageText(0);
      }

      this.answer.emit({
        field: 'birthday',
        value: `${this.year}-${this.month}-${this.day}`
      });
    }
  }
}
